export const ROUTES = {
  ADD_USER_ROLES: {
    title: 'adduserrole'
  },
  ADD_GLOSSARY: {
    title: 'addglossary'
  },
  ADMINISTRATION: {
    name: 'Administration',
    label: 'administration',
    path: 'administration',
    url: '/administration'
  },
  APP: {
    title: 'app'
  },
  ATTRIBUTE_ONBOARDING: {
    name: 'Attribute Onboarding',
    label: 'attributeOnboarding',
    path: 'attributeonboarding',
    url: '/administration/attributeonboarding'
  },
  BASE_URL: '/',
  BLOCKLIST: {
    label: 'blocklists',
    name: 'Blocklists'
  },
  COMMERCIAL: {
    name: 'Commercial',
    label: 'commercialPerformance',
    path: 'commercialperformance',
    url: '/reporting/commercialperformance'
  },
  CREATOR: {
    name: 'Reporting',
    label: 'reporting',
    path: 'creator',
    url: '/creator'
  },
  CAMPAIGN_BUILDER: {
    name: 'Campaign Builder',
    label: 'campaignBuilder',
    path: 'campaignbuilder',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_GEOGRAPHY: {
    name: 'Campaign Builder',
    label: 'campaignBuilder',
    path: 'campaignbuilder/:geography',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/:geography/:format',
    label: 'campaignBuilder',
    url: '/campaignbuilder'
  },
  CAMPAIGN_BUILDER_EDIT: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/campaign/:id',
    label: 'campaignBuilder',
    url: '/campaignbuilder/campaign'
  },
  EDIT_LAUNCH_CAMPAIGN: {
    name: 'Campaign Builder',
    path: 'campaignbuilder/campaign/launch/:id',
    label: 'campaignBuilder',
    url: '/campaignbuilder/campaign/launch'
  },
  CAMPAIGN_DASHBOARD: {
    name: 'Campaign Dashboard',
    path: 'campaigndashboard',
    label: 'campaignDashboard',
    url: '/campaigndashboard'
  },
  ZENITH_BILLING: {
    name: 'Zenith Billing',
    path: 'zenithbilling',
    label: 'zenithBilling',
    url: '/zenithbilling'
  },
  ORGANISATION_MANAGEMENT: {
    name: 'Organisation Management',
    path: 'organisations',
    label: 'organisationManagement',
    url: '/administration/organisations'
  },
  CLIENT_MANAGEMENT: {
    name: 'Client Management',
    path: 'clientmanagement',
    label: 'clientManagement',
    url: '/administration/clientmanagement'
  },
  ZENITH_MANAGEMENT: {
    name: 'Zenith Management',
    path: 'zenith',
    label: 'zenithManagement',
    url: '/administration/zenith'
  },
  CREATE_PASSWORD: {
    path: 'createpassword',
    title: 'Create Password'
  },
  CREATOR_ADMIN: {
    name: 'Attribute Management',
    path: 'creatoradmin',
    label: 'attributeManagement',
    url: '/administration/creatoradmin'
  },
  CSS_HELPER_CLASS: {
    path: 'style',
    title: 'Style'
  },
  CATAGORY_BLOCKLISTS: {
    name: 'Blocklists',
    label: 'blocklists',
    path: 'blocklists',
    url: '/administration/blocklists'
  },
  CONTACT_US: {
    title: 'contactus'
  },
  CONFIRM_UPLOAD: {
    label: 'confirmUpload',
    path: 'confirmupload'
  },
  DASHBOARD: {
    name: 'Dashboard',
    path: 'dashboard',
    label: 'dashboard',
    url: '/dashboard'
  },
  DATA_USAGE: {
    name: 'Data Usage',
    path: 'datausage',
    label: 'dataUsage',
    url: '/reporting/datausage'
  },
  DEFAULT_LAYOUT: {
    name: 'DefaultLayout',
    path: '',
    label: 'home',
    url: '/'
  },
  DELETE_CATEGORY_BLACKLIST: {
    label: 'deletecategoryblocklist'
  },
  DELETE_SSPID: {
    label: 'deletesspid'
  },
  DELETE_USER_ROLES: {
    label: 'deleteuserrole'
  },
  EDIT_USER: {
    label: 'edituser'
  },
  EMPTY_URL: '',
  FORGOT_PASSWORD: {
    path: 'forgotpassword',
    label: 'forgotPassword'
  },
  INVITE_USER: {
    label: 'inviteuser'
  },
  CREATE_SSPID: {
    label: 'createsspid',
    path: 'createsspid'
  },
  EDIT_SSPID: {
    label: 'editsspid',
    path: 'editsspid'
  },
  LEAVE_CONFIRMATION: {
    path: 'leaveconfirmation',
    label: 'leaveConfirmation'
  },
  LOGIN: {
    path: 'login',
    label: 'Login'
  },
  LANDING_PAGE: {
    name: 'Landing Page',
    label: 'landingPage'
  },
  OTHERS: '**',
  PAGE_400V: {
    path: '400v',
    label: 'userVerificationLinkExpired'
  },
  PAGE_401: {
    path: '401',
    label: 'unAuthorized'
  },
  PAGE_404: {
    path: '404',
    label: 'pageNotFound'
  },
  PAGE_500: {
    path: '500',
    url: '500',
    label: 'internalServerError'
  },
  PASSWORD_RESET: {
    path: 'passwordResetLink/:token',
    type: 'passwordreset'
  },
  PRICING: {
    name: 'Ratecard Pricing',
    label: 'ratecardPricing',
    path: 'pricing',
    url: '/administration/pricing'
  },
  REGISTER: {
    path: 'register',
    label: 'register'
  },
  REPORTING: {
    name: 'Reporting',
    path: 'reporting',
    label: 'reporting',
    url: '/reporting'
  },
  AUDIENCE_BUILDER: {
    name: 'Audience Builder',
    label: 'audienceBuilder',
    path: 'audiencebuilder',
    url: '/audiencebuilder'
  },
  AUDIENCE_BUILDER_ID: {
    name: 'Audience Builder',
    label: 'audienceBuilder',
    path: 'audiencebuilder/:id',
    url: '/audiencebuilder'
  },
  SSPIDMANAGEMENT: {
    name: 'SSP ID Management',
    label: 'sspIdManagement',
    path: 'sspidmanagement',
    url: '/administration/sspidmanagement'
  },
  SUPPORT: {
    name: 'Support',
    label: 'support',
    path: 'support',
    url: '/administration/support'
  },
  SYSTEM_PERFORMANCE: {
    name: 'System Performance',
    label: 'systemPerformance',
    path: 'systemperformance',
    url: '/reporting/systemperformance'
  },
  UNSAVED_CHANGE_CONFIRMATION: {
    label: 'unsavedchangeconfirmation'
  },
  USER_ACTIVATION: {
    path: 'activationLink/:token',
    type: 'useractivation'
  },
  USER_MANAGEMENT: {
    name: 'User Management',
    label: 'userManagement',
    path: 'usermanagement',
    url: '/administration/usermanagement'
  },
  USER_ROLES: {
    name: 'User Roles',
    label: 'userRoles',
    path: 'role',
    url: '/administration/role'
  },
  SETUP_2FA: {
    path: 'setup2fa',
    label: 'setup2FA',
    url: '/setup2fa'
  },
  VERIFY_2FA: {
    path: 'verify2fa',
    label: 'verify2FA',
    url: '/verify2fa'
  },
  PRESET_MANAGEMENT: {
    name: 'Preset Management',
    label: 'presetManagement',
    path: 'presetmanagement',
    url: '/administration/presetmanagement'
  },
  BESPOKE_ATTRIBUTE_MANAGEMENT: {
    name: 'Bespoke Attribute Management',
    label: 'bespokeAttributeManagement',
    path: 'bespokemanagement',
    url: '/administration/bespokemanagement'
  },
  SETTINGS: {
    name: 'Settings',
    label: 'settings',
    path: 'settings',
    url: '/settings'
  },
  LOCALIZATIONS: {
    name: 'Localisations',
    label: 'localisations',
    path: 'localisations',
    url: '/administration/localisations'
  }
};
