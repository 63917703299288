import { ROUTES } from './novatiq-route.constant';

export const USER_FUNCTIONS = [
  {
    id: 1,
    roleId: 1,
    name: ROUTES.AUDIENCE_BUILDER.name
  },
  {
    id: 2,
    roleId: 2,
    name: ROUTES.CAMPAIGN_BUILDER.name
  },
  {
    id: 3,
    roleId: 3,
    name: ROUTES.CAMPAIGN_DASHBOARD.name
  },
  {
    id: 4,
    roleId: 4,
    name: ROUTES.COMMERCIAL.name
  },
  {
    id: 5,
    roleId: 5,
    name: ROUTES.SYSTEM_PERFORMANCE.name
  },
  {
    id: 6,
    roleId: 6,
    name: ROUTES.DATA_USAGE.name
  },
  {
    id: 7,
    name: ROUTES.USER_MANAGEMENT.name
  },
  {
    id: 8,
    name: ROUTES.USER_ROLES.name
  },
  {
    id: 9,
    roleId: 7,
    name: ROUTES.PRICING.name
  },
  {
    id: 10,
    roleId: 8,
    name: ROUTES.CLIENT_MANAGEMENT.name
  },
  {
    id: 11,
    roleId: 9,
    name: ROUTES.ATTRIBUTE_ONBOARDING.name
  },
  {
    id: 12,
    roleId: 10,
    name: ROUTES.CREATOR_ADMIN.name
  },

  {
    id: 13,
    roleId: 11,
    name: ROUTES.CATAGORY_BLOCKLISTS.name
  },
  {
    parentId: 5,
    id: 14,
    name: ROUTES.SSPIDMANAGEMENT.name
  },
  {
    id: 15,
    roleId: 12,
    name: ROUTES.SUPPORT.name
  },
  {
    id: 16,
    roleId: 13,
    name: ROUTES.LANDING_PAGE.name
  },
  {
    id: 17,
    name: ROUTES.PRESET_MANAGEMENT.name
  },
  {
    id: 18,
    name: ROUTES.ORGANISATION_MANAGEMENT.name
  },
  {
    id: 19,
    name: ROUTES.ZENITH_MANAGEMENT.name
  },
  {
    id: 20,
    name: ROUTES.ZENITH_BILLING.name
  },
  {
    id: 21,
    name: ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.name
  },
  {
    id: 22,
    name: ROUTES.LOCALIZATIONS.name
  }
];
