import { AUTHORIZATION_CLIENT, navigationMenuItems, ORGANISATION_TYPE, ROUTES } from '@/_contants';
import { NovatiqGeographyHelper } from '@/_helpers';
import { EncryptionHelper } from '@/_helpers/encryption';
import { LanguageService } from '@/_services/language.service';
import { AuthorizationClientModel, AuthorizationTokenModel, TIME_CONVERSION, UserInfoModel } from '@/_types';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqAuthorizationClient {
  private readonly currentUser: BehaviorSubject<AuthorizationClientModel>;
  private languageService: LanguageService;

  get accessToken(): string {
    const token = this._getAuthenticatedUser()?.token?.accessToken;
    return token ? this.encryptionHelper.decrypt(token) : token;
  }

  get expiresAt(): number {
    return this._getAuthenticatedUser()?.token?.expiresAt;
  }

  get expiresIn(): number {
    return this._getAuthenticatedUser()?.token?.expiresIn;
  }

  get isAuthenticated(): boolean {
    return localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_USER) !== null;
  }

  get loggedInUser(): UserInfoModel {
    return this._getAuthenticatedUser()?.userInfo;
  }

  get isNovatiqUser(): boolean {
    return this._getAuthenticatedUser()?.userInfo.organisation?.type === ORGANISATION_TYPE.NOVATIQ;
  }

  get isDataOwner(): boolean {
    return this._getAuthenticatedUser()?.userInfo.organisation?.type === ORGANISATION_TYPE.DATA_OWNER;
  }

  get isDataPartner(): boolean {
    return this._getAuthenticatedUser()?.userInfo.organisation?.type === ORGANISATION_TYPE.DATA_PARTNER;
  }

  get isAgency(): boolean {
    return this._getAuthenticatedUser()?.userInfo.organisation?.type === ORGANISATION_TYPE.AGENCY;
  }

  get refreshTokenIn(): number {
    const value = 45000;
    return this._getAuthenticatedUser()?.token?.refreshTokenIn || value;
  }

  get sessionExpired(): boolean {
    return Date.now() > this.expiresAt && this.isAuthenticated;
  }

  get landingPage(): string {
    const landingPage = this._getAuthenticatedUser()?.userInfo?.landingPage;
    if (landingPage) {
      const data = navigationMenuItems.filter((navigationMenuItem) => navigationMenuItem.name === landingPage)[0];
      return data.routerLink[0];
    } else {
      const pages = this._getAuthenticatedUser()?.userInfo?.functions;
      // if user has access to audience builder then redirect user there
      // else to 1st page from the list of functions
      if (pages.includes(ROUTES.AUDIENCE_BUILDER.name)) {
        return ROUTES.AUDIENCE_BUILDER.url;
      } else {
        const pageWithAccess = pages[0];
        for (const key in ROUTES) {
          if (ROUTES[key]?.name === pageWithAccess) {
            return ROUTES[key].url;
          }
        }
      }
    }
  }

  get isInternalTrafficker(): boolean {
    return this._getAuthenticatedUser()?.userInfo?.organisation?.isInternal;
  }

  get locale(): string {
    return this._getAuthenticatedUser()?.userInfo?.language;
  }

  constructor(
    private readonly encryptionHelper: EncryptionHelper,
    private readonly _geographyHelper: NovatiqGeographyHelper,
    private injector: Injector
  ) {
    this.currentUser = new BehaviorSubject<AuthorizationClientModel>(this._getAuthenticatedUser());
  }

  addUserSession(authResponse): UserInfoModel {
    this._setSession(authResponse);
    return this.loggedInUser;
  }

  getCurrentUser() {
    return this.currentUser.asObservable();
  }

  removeUserSession() {
    this._removeSession();
  }

  refreshUserSession(tokenResponse) {
    this._refreshSession(tokenResponse);
  }

  private _getAuthenticatedUser(): AuthorizationClientModel {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_USER)) as AuthorizationClientModel;
  }

  private _refreshSession(tokenResponse: any) {
    const authenticatedUser = this._getAuthenticatedUser();
    const token = this._setAccessToken(
      tokenResponse.accessToken,
      tokenResponse.expiresIn,
      authenticatedUser.token.tokenType
    );
    this._setUserSession(token, authenticatedUser.userInfo);
  }

  public updateDefaultLanguage(defaultLang: string) {
    const authenticatedUser = this._getAuthenticatedUser();
    if (authenticatedUser) {
      authenticatedUser.userInfo.language = defaultLang;
      this._setUserSession(authenticatedUser.token, authenticatedUser.userInfo);
    }
  }

  private _removeSession() {
    this.currentUser.next(null);
    this._geographyHelper.clearGeographies();
    localStorage.removeItem(AUTHORIZATION_CLIENT.SESSION_USER);
    //clear locale
    sessionStorage.removeItem(AUTHORIZATION_CLIENT.LOCALE);
    if (!this.languageService) {
      this.languageService = this.injector.get(LanguageService);
    }
    this.languageService.clearLocale();
  }

  private _setAccessToken(accessToken: string, expiresIn: number, tokenType: string): AuthorizationTokenModel {
    const value = 100;
    const expiresInSeconds = expiresIn * TIME_CONVERSION.TIME_MINUTE_TO_SECONDS;
    return {
      accessToken: this.encryptionHelper.encrypt(accessToken),
      expiresAt: expiresInSeconds * TIME_CONVERSION.TIME_SECONDS_TO_MILLISECONDS + Date.now(),
      expiresIn: expiresInSeconds,
      refreshTokenIn: (expiresInSeconds - value) * TIME_CONVERSION.TIME_SECONDS_TO_MILLISECONDS,
      tokenType
    } as AuthorizationTokenModel;
  }

  private _setSession(authResult) {
    const token = this._setAccessToken(authResult.accessToken, authResult.expiresIn, authResult.tokenType);
    this._setUserSession(token, authResult.userInfo);
  }

  private _setUserSession(token: AuthorizationTokenModel, userInfo: UserInfoModel) {
    this.currentUser.next({ token, userInfo } as AuthorizationClientModel);
    localStorage.setItem(AUTHORIZATION_CLIENT.SESSION_USER, JSON.stringify({ token, userInfo }));
  }
}
