import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NovatiqAuthorizationClient } from '@/_auth-client';
import { AUTHORIZATION_API_URL, HTTP_CONSTANT, LANGUAGE_CODE } from '@/_contants';
import { environment } from '@environments/environment';

@Injectable()
export class NovatiqHttpInterceptor implements HttpInterceptor {
  constructor(private readonly authClient: NovatiqAuthorizationClient) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      headers: this.addRequestHeaders(request.url, request.method)
    });

    return next.handle(request);
  }

  private addRequestHeaders(url: string, method: string) {
    let headers = new HttpHeaders();
    const token = this.canAddAuthorizationToken(url);

    if (token) {
      headers = headers.append('Authorization', token);
    }

    /*if (
      method !== HTTP_CONSTANT.DELETE &&
      url !== environment.NovatiqAPIURL + AUTHORIZATION_API_URL.GET_SUPPORT_MANUAL + LANGUAGE_CODE.EN
    ) {
      const contentType = 'application/json';
      headers = headers.append('Content-Type', contentType);
    }*/
    return headers;
  }

  private canAddAuthorizationToken(url: string) {
    if (url !== environment.NovatiqAPIURL + AUTHORIZATION_API_URL.LOGIN && !url.includes('.json')) {
      return this.authClient.accessToken;
    }
    return null;
  }
}
