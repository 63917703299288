import { MapSideBarData } from '@/_helpers';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@codeandweb/ngx-translate';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[] = [];
  constructor(
    public layoutService: LayoutService,
    private readonly mapSideBarData: MapSideBarData,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    let items: MenuItem[] = this.getMenuItems();
    this.model = [
      {
        label: '',
        items: items
      }
    ];
  }

  getMenuItems() {
    return this.mapSideBarData.initload();
  }
}
