import { NovatiqAuthorizationClient } from '@/_auth-client';
import { ROUTES } from '@/_contants';
import { ILanguage, LanguageService } from '@/_services/language.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@codeandweb/ngx-translate';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { LayoutService } from './service/app.layout.service';
import { NovatiqMessageService } from './service/message.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy, OnChanges {
  items!: MenuItem[];
  @Output() menuclicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;
  userName: any;
  // Define your language options
  languages: ILanguage[] = [];
  sessionLanguage: any;
  @Input() locales;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public layoutService: LayoutService,
    private readonly authorizationClient: NovatiqAuthorizationClient,
    public translate: TranslateService,
    private router: Router,
    private languageService: LanguageService,
    private messageService: NovatiqMessageService
  ) {
    this.items = [
      {
        label: 'Language',
        icon: 'pi pi-globe top-menu-icon',
        escape: true
      },
      {
        label: ROUTES.SETTINGS.label,
        icon: 'pi pi-cog top-menu-icon',
        routerLink: [ROUTES.SETTINGS.url],
        styleClass: this.isActive(ROUTES.SETTINGS.url) ? 'highlight' : '' // Add highlight class
      },
      {
        label: ROUTES.SUPPORT.path,
        tooltip: ROUTES.SUPPORT.path,
        icon: 'pi support-icon top-menu-icon',
        routerLink: [ROUTES.SUPPORT.url],
        styleClass: this.isActive(ROUTES.SUPPORT.url) ? 'highlight' : ''
      },
      {
        label: 'logout',
        icon: 'pi pi-sign-out top-menu-icon',
        command: () => {
          this.logoutCalled();
        }
      }
    ];

    this.languageService.sessionLanguage$.subscribe((language) => {
      this.sessionLanguage = language; // update selectedLanguage when it changes
    });
    this.userName = this.authorizationClient.loggedInUser.name || this.authorizationClient.loggedInUser.userName;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('locales')) {
      this.languages = this.locales.map((language, index) => ({
        ...language,
        default: language.iso == this.authorizationClient.locale
      }));
    }
  }

  isActive(route: string): boolean {
    return this.router.url === route;
  }

  onLanguageChange({}) {
    this.languageService.setSessionLanguage(this.sessionLanguage); // update sessionLanguage
    this.languageService.useLanguage(this.sessionLanguage);
  }

  logoutCalled() {
    this.menuclicked.emit('logout');
  }

  showErrorMessage(message) {
    this.messageService.sendMessage(message || '', 'error');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
