import { ROUTES } from '@/_contants';

export const UserRole = {
  ADMIN: [ROUTES.USER_MANAGEMENT.name],
  SUPERDAMIN: [
    ROUTES.COMMERCIAL.name,
    ROUTES.SYSTEM_PERFORMANCE.name,
    ROUTES.DATA_USAGE.name,
    ROUTES.CAMPAIGN_BUILDER.name,
    ROUTES.CAMPAIGN_DASHBOARD.name,
    ROUTES.CREATOR_ADMIN.name,
    ROUTES.AUDIENCE_BUILDER.name,
    ROUTES.COMMERCIAL.name,
    ROUTES.SYSTEM_PERFORMANCE.name,
    ROUTES.DATA_USAGE.name,
    ROUTES.CATAGORY_BLOCKLISTS.name,
    ROUTES.ORGANISATION_MANAGEMENT.name,
    ROUTES.CLIENT_MANAGEMENT.name,
    ROUTES.ZENITH_MANAGEMENT.name,
    ROUTES.ATTRIBUTE_ONBOARDING.name,
    ROUTES.PRICING.name,
    ROUTES.SSPIDMANAGEMENT.name,
    ROUTES.SUPPORT.name,
    ROUTES.USER_MANAGEMENT.name,
    ROUTES.PRESET_MANAGEMENT.name,
    ROUTES.USER_ROLES.name,
    ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.name,
    ROUTES.LOCALIZATIONS.name
  ]
};
