import { ICONS } from '../shared/helpers/icons';
import { ROUTES } from './novatiq-route.constant';

export const mainMenuItems: IMenuItem[] = [
  {
    id: 1,
    name: ROUTES.AUDIENCE_BUILDER.name,
    label: ROUTES.AUDIENCE_BUILDER.label,
    routerLink: [ROUTES.AUDIENCE_BUILDER.url],
    path: ROUTES.AUDIENCE_BUILDER.path,
    icon: ICONS.CUBE,
    image: 'assets/img/icons/audience.svg'
  },
  {
    id: 2,
    name: ROUTES.CAMPAIGN_BUILDER.name,
    label: ROUTES.CAMPAIGN_BUILDER.label,
    routerLink: [ROUTES.CAMPAIGN_BUILDER.url],
    path: ROUTES.CAMPAIGN_BUILDER.path,
    icon: ICONS.CUBES,
    image: 'assets/img/icons/campaign-builder.svg'
  },
  {
    id: 3,
    name: ROUTES.CAMPAIGN_DASHBOARD.name,
    label: ROUTES.CAMPAIGN_DASHBOARD.label,
    routerLink: [ROUTES.CAMPAIGN_DASHBOARD.url],
    path: ROUTES.CAMPAIGN_DASHBOARD.path,
    icon: ICONS.TABLE,
    image: 'assets/img/icons/campaign-dashboard.svg'
  },
  {
    id: 4,
    name: ROUTES.ZENITH_BILLING.name,
    label: ROUTES.ZENITH_BILLING.label,
    routerLink: [ROUTES.ZENITH_BILLING.url],
    path: ROUTES.ZENITH_BILLING.path,
    icon: ICONS.CALC,
    image: 'assets/img/icons/zenith-billing.svg'
  },
  {
    id: 5,
    items: [],
    name: ROUTES.REPORTING.name,
    label: ROUTES.REPORTING.label,
    routerLink: [ROUTES.REPORTING.url],
    icon: ICONS.CHART,
    image: 'assets/img/icons/reporting.svg'
  },
  {
    id: 6,
    items: [],
    name: ROUTES.ADMINISTRATION.name,
    label: ROUTES.ADMINISTRATION.label,
    routerLink: [ROUTES.ADMINISTRATION.url],
    icon: ICONS.SETTINGS,
    image: 'assets/img/icons/administration.svg'
  }
];

export const navigationMenuItems: IMenuItem[] = [
  {
    parentId: 1,
    id: 1,
    isChildMenu: false,
    roleId: 1,
    name: ROUTES.AUDIENCE_BUILDER.name,
    label: ROUTES.AUDIENCE_BUILDER.label,
    routerLink: [ROUTES.AUDIENCE_BUILDER.url],
    path: ROUTES.AUDIENCE_BUILDER.path
  },
  {
    parentId: 2,
    id: 2,
    roleId: 2,
    isChildMenu: false,
    name: ROUTES.CAMPAIGN_BUILDER.name,
    label: ROUTES.CAMPAIGN_BUILDER.label,
    routerLink: [ROUTES.CAMPAIGN_BUILDER.url],
    path: ROUTES.CAMPAIGN_BUILDER.path
  },
  {
    parentId: 3,
    id: 3,
    roleId: 3,
    isChildMenu: false,
    name: ROUTES.CAMPAIGN_DASHBOARD.name,
    label: ROUTES.CAMPAIGN_DASHBOARD.label,
    routerLink: [ROUTES.CAMPAIGN_DASHBOARD.url],
    path: ROUTES.CAMPAIGN_DASHBOARD.path
  },
  {
    parentId: 4,
    id: 4,
    isChildMenu: false,
    roleId: 4,
    name: ROUTES.ZENITH_BILLING.name,
    label: ROUTES.ZENITH_BILLING.label,
    routerLink: [ROUTES.ZENITH_BILLING.url],
    path: ROUTES.ZENITH_BILLING.path
  },
  {
    parentId: 5,
    id: 5,
    roleId: 5,
    isChildMenu: true,
    name: ROUTES.COMMERCIAL.name,
    label: ROUTES.COMMERCIAL.label,
    routerLink: [ROUTES.COMMERCIAL.url],
    path: ROUTES.COMMERCIAL.path,
    image: 'assets/img/icons/commercial-reporting.svg'
  },
  {
    parentId: 5,
    id: 6,
    roleId: 6,
    isChildMenu: true,
    name: ROUTES.SYSTEM_PERFORMANCE.name,
    label: ROUTES.SYSTEM_PERFORMANCE.label,
    routerLink: [ROUTES.SYSTEM_PERFORMANCE.url],
    path: ROUTES.SYSTEM_PERFORMANCE.path,
    image: 'assets/img/icons/system-performance.svg'
  },
  {
    parentId: 5,
    id: 16,
    roleId: 7,
    isChildMenu: true,
    name: ROUTES.DATA_USAGE.name,
    label: ROUTES.DATA_USAGE.label,
    routerLink: [ROUTES.DATA_USAGE.url],
    path: ROUTES.DATA_USAGE.path,
    image: 'assets/img/icons/data-usage.svg'
  },
  {
    parentId: 6,
    id: 8,
    isChildMenu: true,
    name: ROUTES.USER_MANAGEMENT.name,
    label: ROUTES.USER_MANAGEMENT.label,
    routerLink: [ROUTES.USER_MANAGEMENT.url],
    path: ROUTES.USER_MANAGEMENT.path,
    image: 'assets/img/icons/user-management.svg'
  },
  {
    parentId: 6,
    id: 8,
    isChildMenu: true,
    name: ROUTES.USER_ROLES.name,
    label: ROUTES.USER_ROLES.label,
    routerLink: [ROUTES.USER_ROLES.url],
    path: ROUTES.USER_ROLES.path,
    image: 'assets/img/icons/user-roles.svg'
  },
  {
    parentId: 6,
    id: 9,
    roleId: 7,
    isChildMenu: true,
    name: ROUTES.PRICING.name,
    label: ROUTES.PRICING.label,
    routerLink: [ROUTES.PRICING.url],
    path: ROUTES.PRICING.path,
    image: 'assets/img/icons/rate-card-pricing.svg'
  },
  {
    parentId: 6,
    id: 10,
    roleId: 8,
    isChildMenu: true,
    name: ROUTES.ORGANISATION_MANAGEMENT.name,
    label: ROUTES.ORGANISATION_MANAGEMENT.label,
    routerLink: [ROUTES.ORGANISATION_MANAGEMENT.url],
    path: ROUTES.ORGANISATION_MANAGEMENT.path,
    image: 'assets/img/icons/organisation-management.svg'
  },
  {
    parentId: 6,
    id: 11,
    roleId: 9,
    isChildMenu: true,
    name: ROUTES.CLIENT_MANAGEMENT.name,
    label: ROUTES.CLIENT_MANAGEMENT.label,
    routerLink: [ROUTES.CLIENT_MANAGEMENT.url],
    path: ROUTES.CLIENT_MANAGEMENT.path,
    image: 'assets/img/icons/client-management.svg'
  },
  {
    parentId: 6,
    id: 10,
    roleId: 8,
    isChildMenu: true,
    name: ROUTES.ZENITH_MANAGEMENT.name,
    label: ROUTES.ZENITH_MANAGEMENT.label,
    routerLink: [ROUTES.ZENITH_MANAGEMENT.url],
    path: ROUTES.ZENITH_MANAGEMENT.path,
    image: 'assets/img/icons/zenith-management.svg'
  },
  {
    parentId: 6,
    id: 11,
    roleId: 9,
    isChildMenu: true,
    name: ROUTES.ATTRIBUTE_ONBOARDING.name,
    label: ROUTES.ATTRIBUTE_ONBOARDING.label,
    routerLink: [ROUTES.ATTRIBUTE_ONBOARDING.url],
    path: ROUTES.ATTRIBUTE_ONBOARDING.path,
    image: 'assets/img/icons/attribute-onboarding.svg'
  },
  {
    parentId: 6,
    id: 13,
    roleId: 11,
    isChildMenu: true,
    name: ROUTES.CREATOR_ADMIN.name,
    label: ROUTES.CREATOR_ADMIN.label,
    routerLink: [ROUTES.CREATOR_ADMIN.url],
    path: ROUTES.CREATOR_ADMIN.path,
    image: 'assets/img/icons/attribute-management.svg'
  },
  {
    parentId: 6,
    id: 19,
    isChildMenu: true,
    name: ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.name,
    label: ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.label,
    routerLink: [ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.url],
    path: ROUTES.BESPOKE_ATTRIBUTE_MANAGEMENT.path,
    image: 'assets/img/icons/attribute-management.svg'
  },
  {
    parentId: 6,
    id: 14,
    isChildMenu: true,
    name: ROUTES.PRESET_MANAGEMENT.name,
    label: ROUTES.PRESET_MANAGEMENT.label,
    routerLink: [ROUTES.PRESET_MANAGEMENT.url],
    path: ROUTES.PRESET_MANAGEMENT.path,
    image: 'assets/img/icons/preset-management.svg'
  },
  {
    parentId: 6,
    id: 15,
    isChildMenu: true,
    name: ROUTES.LOCALIZATIONS.name,
    label: ROUTES.LOCALIZATIONS.label,
    routerLink: [ROUTES.LOCALIZATIONS.url],
    path: ROUTES.LOCALIZATIONS.path,
    image: 'assets/img/icons/preset-management.svg'
  },
  {
    parentId: 6,
    id: 16,
    roleId: 11,
    isChildMenu: true,
    name: ROUTES.CATAGORY_BLOCKLISTS.name,
    label: ROUTES.CATAGORY_BLOCKLISTS.label,
    routerLink: [ROUTES.CATAGORY_BLOCKLISTS.url],
    path: ROUTES.CATAGORY_BLOCKLISTS.path,
    image: 'assets/img/icons/blocklists.svg'
  },
  {
    parentId: 6,
    id: 17,
    isChildMenu: true,
    name: ROUTES.SSPIDMANAGEMENT.name,
    label: ROUTES.SSPIDMANAGEMENT.label,
    routerLink: [ROUTES.SSPIDMANAGEMENT.url],
    path: ROUTES.SSPIDMANAGEMENT.path,
    image: 'assets/img/icons/sspid-management.svg'
  },
  {
    parentId: 6,
    id: 18,
    roleId: 13,
    isChildMenu: true,
    name: ROUTES.LANDING_PAGE.name,
    label: ROUTES.LANDING_PAGE.label
  }
];

export const exceptionalNavMenuItems: IMenuItem[] = [
  {
    name: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY.name,
    label: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY.label,
    routerLink: [ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY.url],
    path: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY.path
  },
  {
    name: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT.name,
    label: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT.label,
    routerLink: [ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT.url],
    path: ROUTES.CAMPAIGN_BUILDER_GEOGRAPHY_FORMAT.path
  },
  {
    name: ROUTES.CAMPAIGN_BUILDER_EDIT.name,
    label: ROUTES.CAMPAIGN_BUILDER_EDIT.label,
    routerLink: [ROUTES.CAMPAIGN_BUILDER_EDIT.url],
    path: ROUTES.CAMPAIGN_BUILDER_EDIT.path
  },
  {
    name: ROUTES.EDIT_LAUNCH_CAMPAIGN.name,
    label: ROUTES.EDIT_LAUNCH_CAMPAIGN.label,
    routerLink: [ROUTES.EDIT_LAUNCH_CAMPAIGN.url],
    path: ROUTES.EDIT_LAUNCH_CAMPAIGN.path
  },
  {
    name: ROUTES.AUDIENCE_BUILDER_ID.name,
    label: ROUTES.AUDIENCE_BUILDER_ID.label,
    routerLink: [ROUTES.AUDIENCE_BUILDER_ID.url],
    path: ROUTES.AUDIENCE_BUILDER_ID.path
  }
];

export const topRightSettingsMenu: IMenuItem[] = [
  {
    parentId: 1,
    id: 1,
    roleId: 1,
    name: ROUTES.SUPPORT.name,
    label: ROUTES.SUPPORT.label,
    routerLink: [ROUTES.SUPPORT.url],
    path: ROUTES.SUPPORT.path
  }
];

export interface IMenuItem {
  parentId?: number; // ID of the parent menu
  id?: number; // Unique ID of the menu item
  isChildMenu?: boolean; // Indicates if this is a child menu
  roleId?: number; // ID of the role associated with this menu item
  name: string;
  label: string; // Display label for the menu item
  routerLink?: string[]; // Array of strings for routing links
  path?: string; // Path associated with the menu item
  icon?: string;
  image?: string;
  items?: IMenuItem[];
}
