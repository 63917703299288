import { NovatiqAuthorizationClient } from '@/_auth-client';
import { AuthorizationHelper, NovatiqLoadingSpinnerHelper, NovatiqPopupHelper } from '@/_helpers';
import { UserInfoModel } from '@/_types';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@codeandweb/ngx-translate';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ROUTES } from './_contants';
import { PageSettingService } from './_services';
import { LanguageService } from './_services/language.service';
import { LayoutService } from './layouts/service/app.layout.service';

@Component({
  selector: 'app-body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: UserInfoModel;
  pageSettings: any;
  private isAppIdle: boolean;
  private isIdleWatchOn: boolean;
  private timeOut: any;
  public isShowPopup = false;

  private getCurrentUserSubscription: Subscription;
  private onIdleStartSubcription: Subscription;
  private onIdleEndSubcription: Subscription;
  private routerEventSubscription: Subscription;

  constructor(
    private readonly authorizationClient: NovatiqAuthorizationClient,
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly idle: Idle,
    private readonly loadingSpinner: NovatiqLoadingSpinnerHelper,
    private readonly pageSettingsService: PageSettingService,
    private readonly popupHelper: NovatiqPopupHelper,
    private readonly router: Router,
    public translate: TranslateService,
    private primengConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.setupPrimeNG();
    this.initialiseProperties();
    this.configureIdleTimer();
    this.getCurrentLoggedInUser();
    this.initialiseRouterEvent();
    this.getPageSettings();
    this.languageService.initLanguage();
  }

  setupPrimeNG() {
    this.primengConfig.ripple = true; //enables core ripple functionality

    //optional configuration with the default configuration
    this.layoutService.config = {
      ripple: true, //toggles ripple on and off
      inputStyle: 'outlined', //default style for input elements
      menuMode: 'static', //layout mode of the menu, valid values are "static" and "overlay"
      colorScheme: 'light', //color scheme of the template, valid values are "light" and "dark"
      theme: 'saga-purple', //default component theme for PrimeNG
      scale: 14 //size of the body font size to scale the whole application
    };
  }

  getCurrentLoggedInUser() {
    this.getCurrentUserSubscription = this.authorizationClient.getCurrentUser().subscribe((userLoggedIn) => {
      clearTimeout(this.timeOut);
      this.currentUser = userLoggedIn?.userInfo;
      if (userLoggedIn) {
        this.timeOut = setTimeout(() => {
          this.authorizationHelper.refreshToken();
        }, userLoggedIn.token.refreshTokenIn);
        if (!this.isIdleWatchOn) {
          this.resetIdle(userLoggedIn);
        }
      } else {
        this.idle.stop();
        this.isIdleWatchOn = false;
      }
    });
  }

  resetIdle(userLoggedIn = null) {
    this.isIdleWatchOn = true;
    this.idle.setIdle(userLoggedIn?.token?.expiresIn || this.authorizationClient.expiresIn);
    this.idle.watch();
  }

  resumeUserSession(event: any) {
    this.isAppIdle = false;
    this.resetIdle(event);
  }

  @HostListener('window:beforeunload')
  beforeunloadApplication() {
    if (this.isAppIdle) {
      this.isShowPopup = false;
      this.popupHelper.close();
      this.authorizationClient.removeUserSession();
    }
  }

  private configureIdleTimer() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.onIdleStartSubcription = this.idle.onIdleStart.subscribe(() => {
      this.isAppIdle = true;
      this.isShowPopup = true;
      this.popupHelper.open(this.pageSettings?.expiryLockPopupId);
    });

    this.onIdleEndSubcription = this.idle.onIdleEnd.subscribe(() => {
      this.resetIdle();
    });
  }

  private getPageSettings() {
    this.pageSettingsService.get(ROUTES.APP.title).subscribe(
      (settings) => {
        this.pageSettings = settings;
      },
      (error) => {
        throw error;
      }
    );
  }

  private initialiseProperties() {
    this.isIdleWatchOn = false;
    this.isAppIdle = false;
  }

  private initialiseRouterEvent() {
    this.routerEventSubscription = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      switch (true) {
        case evt instanceof NavigationStart: {
          this.loadingSpinner.start();
          break;
        }
        case evt instanceof NavigationEnd:
        case evt instanceof NavigationCancel:
        case evt instanceof NavigationError: {
          this.loadingSpinner.stop();
          break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.getCurrentUserSubscription?.unsubscribe();
    this.onIdleStartSubcription?.unsubscribe();
    this.onIdleEndSubcription?.unsubscribe();
    this.routerEventSubscription?.unsubscribe();
    clearTimeout(this.timeOut);
    this.beforeunloadApplication();
  }
}
